import React from 'react';
import PropTypes from 'prop-types';

const Material = ({ isActive, name, setMaterial, imageUrl }) => {
  return (
    <div className={isActive ? 'active' : ''} onClick={setMaterial}>
      <span style={{ backgroundImage: `url('${imageUrl}')` }}></span>
      <h5>{name}</h5>
    </div>
  );
};

Material.defaultProps = {
  isActive: false,
};

Material.propTypes = {
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  setMaterial: PropTypes.func.isRequired,
};

export default Material;
