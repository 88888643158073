import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import PlanimeteryImage from './PlainmetryImage';

const PlanimetryModal = ({
  modalIsOpen,
  setModalIsOpen,
  size,
  name,
  PlanimetryMQ,
  PlanimetryPrice,
  PlanimetryRate,
  availability,
  bader,
  loggia,
  loggiaType,
  link,
  PlanimetryImage,
  getPrevious,
  getNext,
  isNext,
  isPrevious,
}) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 12,
        },
        content: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          border: '1px solid #000',
          background: '#fff',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '0px',
          outline: 'none',
          padding: '7px',
        },
      }}
    >
      <span
        className="modal-close"
        onClick={() => setModalIsOpen(false)}
      ></span>
      {isPrevious && (
        <div
          className="modal-arrow back"
          onClick={getPrevious}
          style={{ cursor: 'pointer' }}
        />
      )}
      {isNext && (
        <div
          className="modal-arrow forward"
          onClick={getNext}
          style={{ cursor: 'pointer' }}
        />
      )}

      <div className="PlanimetryBox">
        <div className="planimetry-info">
          <h5 className="size">{size}</h5>
          <h2>{name}</h2>
          <hr></hr>
          <h5 className="mq">
            {PlanimetryMQ} m<sup>2</sup>
          </h5>
          <h5 className="price">AB CHF {PlanimetryPrice}</h5>
          <hr className="variant-2"></hr>
          <h5 className="rate">monatl. Rate* AB CHF {PlanimetryRate}</h5>
          <h5 className="availability">Verfügbar auf den Etagen</h5>
          <h5 className="availability-numbers">{availability}</h5>
          <div className="services">
            <div className="bath">
              <h5>BÄDER</h5>
              <h5 className="quantity">{bader}</h5>
            </div>
            {/* <div className="rooms"><h5>SCHLAFZIMMER</h5 ><h5 className="quantity">{props.schlafzimmer}</h5></div> */}
            {loggia !== '0' && (
              <div className="loggia">
                <h5>{loggiaType}</h5>
                <h5 className="quantity">
                  {loggia} m<sup>2</sup>
                </h5>
              </div>
            )}
          </div>
          <Link className="primary-button" to={link}>
            AUSWÄHLEN
          </Link>
        </div>

        <PlanimeteryImage
          imageSrc={`${process.env.PUBLIC_URL}/img/planimetry/modal/${name}.png`}
        />
      </div>
    </Modal>
  );
};

export default PlanimetryModal;
