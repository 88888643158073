import React from 'react';

const Header = ({ savedRef, showHeaderBackLink }) => {
  const href = savedRef
    ? savedRef
    : 'http://www.lokstadt.ch/de-ch/wohnen/tender.html';

  //const isAppartmentNameValid = sessionStorage.getItem('appartment');

  return (
    <header>
      {showHeaderBackLink ? (
        <a style={{ cursor: 'pointer' }} className="header__back" href={href}>
          <span className="md-hidden">Zurück zur Übersicht.</span>
        </a>
      ) : (
        <div />
      )}
      <a className="header__logo" href="/" style={{ fontSize: '0' }}>
        Startseite
      </a>
      <a className="header__contact-us" href="mailto:tender@lokstadt.ch">
        <span className="md-hidden">Haben Sie Fragen?</span>{' '}
        <span>Kontaktieren Sie uns</span>
      </a>
    </header>
  );
};

export default Header;
