import React, { useState } from 'react';
import PlanimetryBox from '../components/PlanimetryBox';
import PlanimetryModal from '../components/PlanimetryModal';

import PropTypes from 'prop-types';
import Nav from '../components/Nav';
import _ from 'lodash';
import {
  getRate,
  convertToPrice,
  convertToCurrencyValue,
  getFloors,
  getNext,
  getPrevious,
} from '../utils';

const Planimetry = ({ planimetries, typology, typologyCode }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activePlan, setActivePlan] = useState(null);

  return (
    <>
      <Nav typologyCode={typologyCode} />
      <div className="main-container">
        <div className="apartment-planimetry-container">
          {planimetries.length > 0 && (
            <>
              {planimetries.map((plan) => {
                return plan.appartments.some((app) => app.reserved !== true) ? (
                  <PlanimetryBox
                    key={plan.name}
                    setModalIsOpen={() => {
                      setModalIsOpen(true);
                      setActivePlan(plan);
                    }}
                    size={typology.name}
                    name={plan.name}
                    PlanimetryMQ={plan.mq}
                    PlanimetryPrice={convertToCurrencyValue(
                      _.min(
                        plan.appartments.map((itm) =>
                          convertToPrice(itm.basePrice)
                        )
                      )
                    )}
                    PlanimetryRate={convertToCurrencyValue(
                      getRate(
                        _.min(
                          plan.appartments.map((itm) =>
                            convertToPrice(itm.basePrice)
                          )
                        )
                      )
                    )}
                    PlanimetryImage="planimetry-sample__01.png"
                    availability={getFloors(plan)}
                    bader={plan.bathrooms}
                    link={`${typology.code}/${plan.name}`}
                    schlafzimmer="3"
                    loggia={plan.mq_loggia}
                  />
                ) : null;
              })}
              {activePlan && (
                <PlanimetryModal
                  getNext={() => {
                    const next = getNext(planimetries, activePlan);
                    if (next) setActivePlan(next);
                  }}
                  isNext={getNext(planimetries, activePlan) !== null}
                  isPrevious={getPrevious(planimetries, activePlan) !== null}
                  getPrevious={() => {
                    const prev = getPrevious(planimetries, activePlan);
                    if (prev) setActivePlan(prev);
                  }}
                  modalIsOpen={modalIsOpen}
                  setModalIsOpen={setModalIsOpen}
                  size={typology.name}
                  name={activePlan.name}
                  PlanimetryMQ={activePlan.mq}
                  PlanimetryPrice={convertToCurrencyValue(
                    _.min(
                      planimetries
                        .find((pln) => pln.name === activePlan.name)
                        .appartments.map((itm) => convertToPrice(itm.basePrice))
                    )
                  )}
                  PlanimetryRate={convertToCurrencyValue(
                    getRate(
                      _.min(
                        planimetries
                          .find((pln) => pln.name === activePlan.name)
                          .appartments.map((itm) =>
                            convertToPrice(itm.basePrice)
                          )
                      )
                    )
                  )}
                  PlanimetryImage="planimetry-sample__01.png"
                  availability={getFloors(activePlan)}
                  bader={activePlan.bathrooms}
                  link={`${typology.code}/${activePlan.name}`}
                  schlafzimmer="3"
                  loggia={activePlan.mq_loggia}
                  loggiaType={activePlan.external_space}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

Planimetry.propTypes = {
  planimetries: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      mq: PropTypes.string.isRequired,
      mq_loggia: PropTypes.string.isRequired,
      bedrooms: PropTypes.string.isRequired,
      appartments: PropTypes.arrayOf(PropTypes.object).isRequired,
    })
  ).isRequired,
  typology: PropTypes.object.isRequired,
};

export default Planimetry;
