import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import AppoitmentModal from './AppointmentModal';
import Modal from 'react-modal';
import FormModal from './FormModal';

const Form = ({ formImage, typologyCode, data }) => {
  const [appointmentModal, setappointmentModal] = useState(false);
  const [formModal, setFormModal] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    address: '',
    postcode: '',
    location: '',
    email: '',
    note: '',
    phone: '',
    sex: '',
    visitors: '1',
  });
  Modal.setAppElement('#root');

  const resetForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      address: '',
      postcode: '',
      location: '',
      email: '',
      note: '',
      phone: '',
      sex: '',
      visitors: '1',
    });
    setappointmentModal(false);
    setFormModal(false);
  };

  const setInput = (e, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value,
    });
  };

  const isFormCompleted = useCallback(() => {
    for (const [, value] of Object.entries(formData)) {
      if (value.trim() === '') return false;
    }
    return true;
  });

  return (
    <div className="form-container" id="form">
      <div
        className="form-image"
        style={{
          backgroundImage: `url(${formImage})`,
        }}
      />
      <div className="form-info">
        <h2>
          Wenn Sie das Formular ausfüllen, erhalten Sie per E-Mail eine
          Bestätigung mit der Zusammenfassung Ihrer Konfiguration.
        </h2>
        {/*<h5>Wenn Sie das Formular ausfüllen,  werden Sie eine Bestätigungs-Mail mit einer Zusammenfassung Ihrer Konfiguration erhalten </h5> */}

        {/* <div className="form-text-box">
                  <h2>AUßERDEM, WENN SIE IHREN TERMIN JETZT BUCHEN</h2>
                  <h5>erhalten Sie exklusive Vorteile </h5>
              </div> */}
        <form
          id="wf-form-checkout-form"
          name="wf-form-checkout-form"
          data-name="checkout-form"
          method="post"
          action="php/form-checkout.php"
          className="form-111"
        >
          <div className="form-6 w-input">
            <select
              name="calendar_anrede"
              id="calendar_1099_anrede"
              style={{
                float: 'left',
                width: '100%',
                margin: '0.5rem 0 0 0',
                padding: '0.5rem',
              }}
              onChange={(e) => {
                setInput(e, 'sex');
              }}
              value={formData.sex === '' ? 0 : formData.sex}
            >
              <option value="0">Anrede*</option>
              <option value="1">Frau</option>
              <option value="2">Herr</option>
            </select>
          </div>
          <input
            type="text"
            className="form-6 w-input"
            maxLength="256"
            name="Vorname"
            data-name="Vorname"
            placeholder="Vorname *"
            onChange={(e) => {
              setInput(e, 'firstName');
            }}
            value={formData.firstName}
            id="Vorname"
            required=""
          />
          <input
            type="text"
            className="form-6 w-input"
            maxLength="256"
            name="Nachname"
            data-name="Nachname"
            placeholder="Nachname *"
            id="Nachname"
            required=""
            onChange={(e) => {
              setInput(e, 'lastName');
            }}
            value={formData.lastName}
          />
          <input
            type="text"
            className="form-6 w-input"
            maxLength="256"
            name="Strasse/Hausnummer "
            data-name="Strasse/Hausnummer "
            placeholder="Strasse/Hausnummer  *"
            id="Strasse"
            required=""
            onChange={(e) => {
              setInput(e, 'address');
            }}
            value={formData.address}
          />
          <input
            type="text"
            className="form-6 w-input"
            maxLength="256"
            name="PLZ"
            data-name="PLZ"
            placeholder="PLZ *"
            id="PLZ"
            required=""
            onChange={(e) => {
              setInput(e, 'postcode');
            }}
            value={formData.postcode}
          />
          <input
            type="text"
            className="form-6 w-input"
            maxLength="256"
            name="Ort "
            data-name="Ort "
            placeholder="Ort *"
            id="Ort "
            required=""
            onChange={(e) => {
              setInput(e, 'location');
            }}
            value={formData.location}
          />
          />
          <input
            type="tel"
            className="form-6 w-input"
            maxLength="256"
            name="Telefon"
            data-name="Telefon"
            placeholder="Telefon *"
            id="Telefon"
            required=""
            onChange={(e) => {
              setInput(e, 'phone');
            }}
            value={formData.phone}
          />
          <input
            type="email"
            className="form-6 w-input"
            maxLength="256"
            name="Email"
            data-name="Email"
            placeholder="Email *"
            id="Email"
            required=""
            onChange={(e) => {
              setInput(e, 'email');
            }}
            value={formData.email}
          />
          <input
            type="textarea"
            className="form-6 w-input"
            maxLength="2048"
            name="Bemerkungen"
            data-name="Bemerkungen"
            placeholder="Bemerkungen *"
            id="Bemerkungen"
            onChange={(e) => {
              setInput(e, 'note');
            }}
            value={formData.note}
          />
          <br />
          <br />
          {
            // appointment form inputs
          }
          {/* {isFormCompleted() && (
            <>
              <h5>
                Zusätzlich, können Sie jetzt Ihren Beratungstermin buchen.{' '}
                <br />
                Wir freuen uns, Sie in unserem Showroom begrüssen zu dürfen:
              </h5>
              <div
                className="visitors"
                style={{
                  display: 'flex',
                  backgroundColor: 'white',
                  color: '#777777',
                  margin: '10px 0',
                  alignItems: 'center',
                }}
              >
                <label
                  className="label"
                  style={{
                    float: 'left',
                    textAlign: 'left',
                    margin: '0.5rem 0 0 0',
                    padding: '0.3rem 0.5rem 0.7rem 0.5rem',
                  }}
                >
                  Anzahl der Personen
                </label>

                <div
                  className="radioButtons"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    width: '60%',
                  }}
                >
                  <div>
                    <input
                      className="custom-radio"
                      type="radio"
                      id="visitors_1"
                      name="calendar_visitors"
                      value="1"
                      checked
                      onChange={(e) => {
                        setInput(e, 'visitors');
                      }}
                    />
                    <label
                      htmlFor="visitors_1"
                      className="anonymous-label-inner"
                    >
                      <span></span>1
                    </label>
                  </div>

                  <div>
                    <input
                      className="custom-radio"
                      type="radio"
                      id="visitors_2"
                      name="calendar_visitors"
                      value="2"
                      // checked={formData.vistors === 2}
                      onChange={(e) => {
                        setInput(e, 'visitors');
                      }}
                    />
                    <label
                      htmlFor="visitors_2"
                      className="anonymous-label-inner"
                    >
                      <span></span>2
                    </label>
                  </div>

                  <div>
                    <input
                      className="custom-radio"
                      type="radio"
                      id="visitors_3"
                      name="calendar_visitors"
                      value="3"
                      // checked={formData.vistors === '3'}
                      onChange={(e) => {
                        setInput(e, 'visitors');
                      }}
                    />
                    <label
                      htmlFor="visitors_3"
                      className="anonymous-label-inner"
                    >
                      <span></span>3
                    </label>
                  </div>
                </div>
              </div>
              <div
                className="primary-button"
                onClick={(e) => {
                  e.preventDefault();
                  if (isFormCompleted()) setappointmentModal(!appointmentModal);
                }}
              >
                Beratungstermin buchen
              </div>
              <AppoitmentModal
                formData={formData}
                typologyCode={typologyCode}
                pdfData={data}
                modalIsOpen={appointmentModal}
                afterDone={resetForm}
                closeModal={() => setappointmentModal(false)}
              />
            </>
          )} */}
          <h5 className="form-note">*Bitte alle Pflichtfelder ausfüllen</h5>
          <div
            type="submit"
            value="FORMULAR SENDEN"
            data-wait="please wait..."
            disabled={!isFormCompleted()}
            className="primary-button"
            onClick={(e) => {
              console.log('clicked', isFormCompleted());

              e.preventDefault();
              if (isFormCompleted()) setFormModal(!formModal);
            }}
          >
            FORMULAR SENDEN
          </div>
          {isFormCompleted() && (
            <FormModal
              formData={formData}
              typologyCode={typologyCode}
              modalIsOpen={formModal}
              afterDone={resetForm}
              pdfData={data}
              closeModal={() => setFormModal(false)}
            />
          )}
        </form>
      </div>
    </div>
  );
};

Form.propTypes = {
  formImage: PropTypes.string.isRequired,
};

export default Form;
