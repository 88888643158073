import React, { useState } from 'react';
import PropTypes from 'prop-types';

const FurnishingBox = ({ name, price, rate, isActive, onSelect }) => {
  const [hover, setHover] = useState(false);
  const [showTooltip, setshowTooltip] = useState(false);
  return (
    <div
      className={`furnishing-box ${isActive ? 'active' : ''}`}
      onClick={onSelect}
    >
      <div className="info" onClick={() => setshowTooltip(!showTooltip)}>
        {showTooltip && <div className="price-tooltip"> CHF {price} </div>}
      </div>
      <h5>AUSSTATTUNGSLINIE</h5>
      <h2>{name}</h2>
      <hr></hr>
      <div className="price">
        monatl. Rate*
        <br />
        <span> CHF {rate}</span>
      </div>
      <div
        className="details"
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={hover ? { backgroundColor: '#d97c50' } : {}}
        onClick={() => {
          window.open(
            `/pdf/Austattungslinie ${name}.pdf`,
            'print_popup',
            'width=1000,height=800,status=0,titlebar=1,scrollbars=0,menubar=0,toolbar=0,location=0,resizable=1'
          );
        }}
      >
        Informationen zur Ausstattungslinie
      </div>
    </div>
  );
};

FurnishingBox.defaultProps = {
  rate: '0.0',
  isActive: false,
};

FurnishingBox.propTypes = {
  name: PropTypes.string.isRequired,
  rate: PropTypes.string,
  price: PropTypes.string,
  isActive: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

export default FurnishingBox;
