import React from 'react';
import PropTypes from 'prop-types';

import Material from './Material';

const OptionBox = ({
  name,
  materials,
  setOptionBox,
  activeFurnishing,
  selected,
}) => {
  return (
    <div className="options-box active">
      <h5>Wählen Sie Ihr </h5>
      <h2>{name}</h2>
      <hr></hr>
      {materials.map((material, idx) => (
        <Material
          key={idx}
          isActive={
            selected[name.toLowerCase()] &&
            selected[name.toLowerCase()].materialName === material.name
          }
          imageUrl={require(`../img/texture/${activeFurnishing.furnishingName}/${name}/${material.name}.jpg`)}
          name={material.name}
          setMaterial={() => setOptionBox(material.name)}
        />
      ))}
    </div>
  );
};

OptionBox.defaultProps = {
  isActive: false,
};

OptionBox.propTypes = {
  name: PropTypes.string.isRequired,
  materials: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeFurnishing: PropTypes.object.isRequired,
  setOptionBox: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
};

export default OptionBox;
