export const SELECT_TYPOLOGY = 'SELECT_TYPOLOGY';
export const SELECT_PLANIMETRY = 'SELECT_PLANIMETRY';
export const SET_CUSTOMIZATION = 'SET_CUSTOMIZATION';
export const RESET_STATE = 'RESET_STATE';

export default {
  SELECT_PLANIMETRY,
  SELECT_TYPOLOGY,
  RESET_STATE,
  SET_CUSTOMIZATION,
};
