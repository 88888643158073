import { createStore, combineReducers } from 'redux';
import { config } from './reducers';

const reducers = { config };

const rootReducer = combineReducers(reducers);

export const store = createStore(rootReducer);

// export const configureStore = () =>
//   createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
