const data = {
  typlogies: [
    {
      name: 'Duplex Atelier EG/1.OG',
      code: 'DUPLEX',
      price_range: 'ca. CHF 1.250.000 - 1.550.000',
    },
    {
      name: '2.5 Zimmer',
      code: 'Z2.5',
      price_range: 'ca. CHF 1.250.000 - 1.550.000',
    },
    {
      name: '3.5 Zimmer',
      code: 'Z3.5',
      price_range: 'ca. CHF 950.000 - 1.200.000',
    },
    {
      name: '4.5 Zimmer',
      code: 'Z4.5',
      price_range: 'ca. CHF 1.050.000 - 1.660.000',
    },
  ],
  packages: [
    {
      id: '1',
      code: 'parking',
      name: 'Tiefgaragenstellplatz',
      price: '45.000',
    },
    {
      id: '2',
      code: 'electric_charging',
      name: 'Ladestation für Elektroauto',
      price: '11.400',
    },
    {
      id: '3',
      code: 'wc_shower',
      name: 'Dusch-WC',
      price: '8.000',
    },
  ],

  planimetries: [
    {
      name: 'A',
      location: 'Hochhaus',
      bedrooms: '3.5',
      bathrooms: '2',
      mq: '113',
      mq_loggia: '0',
      typologyCode: 'Z3.5',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '16.900',
        },
        {
          name: 'Premium',
          price: '43.600',
        },
      ],
      appartments: [
        {
          name: 'TH.0101',
          floors: [1],
          basePrice: '1.199.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
      ],
    },
    {
      name: 'B',
      location: 'Hochhaus',
      bedrooms: '2.5',
      typologyCode: 'Z2.5',
      bathrooms: '1',
      mq: '60',
      mq_loggia: '0',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '9.700',
        },
        {
          name: 'Premium',
          price: '24.100',
        },
      ],
      appartments: [
        {
          name: 'TH.0201',
          floors: [2],
          basePrice: '682.000',
          options: ['wc_shower'],
        },
        {
          name: 'TH.0301',
          floors: [3],
          basePrice: '708.000',
          reserved: false,
          options: ['wc_shower'],
        },
        {
          name: 'TH.0401',
          floors: [4],
          basePrice: '728.000',
          reserved: true,
          options: ['wc_shower'],
        },
        {
          name: 'TH.0501',
          floors: [5],
          basePrice: '761.000',
          reserved: false,
          options: ['wc_shower'],
        },
      ],
    },
    {
      name: 'C',
      location: 'Hochhaus',
      typologyCode: 'Z3.5',
      bedrooms: '3.5',
      bathrooms: '2',
      mq: '87',
      mq_loggia: '7',
      external_space: 'Loggia',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '16.200',
        },
        {
          name: 'Premium',
          price: '42.300',
        },
      ],
      appartments: [
        {
          name: 'TH.0601',
          floors: [6],
          basePrice: '1.163.000',
          options: ['wc_shower'],
        },
        {
          name: 'TH.0701',
          floors: [7],
          basePrice: '1.198.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
        {
          name: 'TH.0801',
          floors: [8],
          basePrice: '1.224.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
        {
          name: 'TH.0901',
          floors: [9],
          basePrice: '1.259.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
        {
          name: 'TH.1001',
          floors: [10],
          basePrice: '1.328.000',
          reserved: true,
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
      ],
    },
    {
      name: 'D',
      location: 'Hochhaus',
      typologyCode: 'Z4.5',
      bedrooms: '4.5',
      bathrooms: '2',
      mq: '125',
      mq_loggia: '0',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '17.900',
        },
        {
          name: 'Premium',
          price: '45.300',
        },
      ],
      appartments: [
        {
          name: 'TH.0102',
          floors: [1],
          basePrice: '1.126.000',
          options: ['wc_shower'],
        },
      ],
    },
    {
      name: 'E',
      location: 'Hochhaus',
      typologyCode: 'Z4.5',
      bedrooms: '4.5',
      bathrooms: '2',
      mq: '139',
      mq_loggia: '17',
      external_space: 'Loggia',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '18.200',
        },
        {
          name: 'Premium',
          price: '45.900',
        },
      ],
      appartments: [
        {
          name: 'TH.0202',
          floors: [2],
          basePrice: '1.459.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
        {
          name: 'TH.0302',
          floors: [3],
          basePrice: '1.515.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
        {
          name: 'TH.0402',
          floors: [4],
          basePrice: '1.540.000',
          reserved: true,
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
        {
          name: 'TH.0502',
          floors: [5],
          basePrice: '1.579.000',
          reserved: true,
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
      ],
    },
    {
      name: 'F',
      location: 'Hochhaus',
      typologyCode: 'Z4.5',
      bedrooms: '4.5',
      bathrooms: '2',
      mq: '112',
      mq_loggia: '7',
      external_space: 'Loggia',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '17.400',
        },
        {
          name: 'Premium',
          price: '44.400',
        },
      ],
      appartments: [
        {
          name: 'TH.0602',
          floors: [6],
          basePrice: '1.348.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
        {
          name: 'TH.0702',
          floors: [7],
          basePrice: '1.393.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
        {
          name: 'TH.0802',
          floors: [8],
          basePrice: '1.427.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
        {
          name: 'TH.0902',
          floors: [9],
          basePrice: '1.464.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
        {
          name: 'TH.1002',
          floors: [10],
          basePrice: '1.543.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
      ],
    },
    {
      name: 'G',
      location: 'Hochhaus',
      typologyCode: 'Z3.5',
      bedrooms: '3.5',
      bathrooms: '2',
      mq: '96',
      mq_loggia: '0',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '16.700',
        },
        {
          name: 'Premium',
          price: '43.100',
        },
      ],
      appartments: [
        {
          name: 'TH.0103',
          floors: [1],
          basePrice: '975.000',
          options: ['wc_shower'],
        },
      ],
    },
    {
      name: 'H',
      location: 'Hochhaus',
      typologyCode: 'Z4.5',
      bedrooms: '4.5',
      bathrooms: '2',
      mq: '112',
      mq_loggia: '7',
      external_space: 'Loggia',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '17.300',
        },
        {
          name: 'Premium',
          price: '44.200',
        },
      ],
      appartments: [
        {
          name: 'TH.0203',
          floors: [2],
          basePrice: '1.093.000',
          reserved: true,
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
        {
          name: 'TH.0303',
          floors: [3],
          basePrice: '1.138.000',
          reserved: true,
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
        {
          name: 'TH.0403',
          floors: [4],
          basePrice: '1.172.000',
          reserved: true,
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
        {
          name: 'TH.0503',
          floors: [5],
          basePrice: '1.217.000',
          reserved: true,
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
        {
          name: 'TH.0603',
          floors: [6],
          basePrice: '1.285.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
        {
          name: 'TH.0703',
          floors: [7],
          basePrice: '1.330.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
      ],
    },
    {
      name: 'I',
      location: 'Hochhaus',
      typologyCode: 'Z4.5',
      bedrooms: '4.5',
      bathrooms: '2',
      external_space: 'Loggia',
      mq: '111',
      mq_loggia: '7',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '17.300',
        },
        {
          name: 'Premium',
          price: '44.200',
        },
      ],
      appartments: [
        {
          name: 'TH.0803',
          floors: [8],
          basePrice: '1.389.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
        {
          name: 'TH.0903',
          floors: [9],
          basePrice: '1.422.000',
          reserved: false,
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
        {
          name: 'TH.1003',
          floors: [10],
          basePrice: '1.500.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
      ],
    },
    {
      name: 'J',
      location: 'Hochhaus',
      typologyCode: 'Z3.5',
      bedrooms: '3.5',
      bathrooms: '2',
      external_space: 'Loggia',
      mq: '87',
      mq_loggia: '7',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '16.200',
        },
        {
          name: 'Premium',
          price: '42.300',
        },
      ],
      appartments: [
        {
          name: 'TH.0204',
          floors: [2],
          basePrice: '922.000',
          reserved: false,
          options: ['wc_shower'],
        },
        {
          name: 'TH.0304',
          floors: [3],
          basePrice: '957.000',
          reserved: true,
          options: ['wc_shower'],
        },
        {
          name: 'TH.0404',
          floors: [4],
          basePrice: '983.000',
          reserved: true,
          options: ['wc_shower'],
        },
        {
          name: 'TH.0504',
          floors: [5],
          basePrice: '1.027.000',
          options: ['wc_shower'],
          reserved: true,
        },
        {
          name: 'TH.0604',
          floors: [6],
          basePrice: '1.088.000',
          options: ['wc_shower'],
          reserved: true,
        },
        {
          name: 'TH.0704',
          floors: [7],
          basePrice: '1.122.000',
          options: ['wc_shower'],
          reserved: true,
        },
        {
          name: 'TH.0804',
          floors: [8],
          basePrice: '1.148.000',
          options: ['wc_shower'],
          reserved: true,
        },
        {
          name: 'TH.0904',
          floors: [9],
          basePrice: '1.183.000',
          options: ['wc_shower'],
          reserved: true,
        },
        {
          name: 'TH.1004',
          floors: [10],
          basePrice: '1.253.000',
          reserved: true,
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
      ],
    },
    {
      name: 'K',
      location: 'Riegel',
      typologyCode: 'DUPLEX',
      bedrooms: '3.5',
      bathrooms: '2',
      mq: '140',
      mq_loggia: '0',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '17.500',
        },
        {
          name: 'Premium',
          price: '40.800',
        },
      ],
      appartments: [
        {
          name: 'TR1.01',
          scala: 'Treppenh. 1',
          floors: [0, 1],
          basePrice: '1.164.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
      ],
    },
    {
      name: 'L',
      location: 'Riegel',
      typologyCode: 'Z4.5',
      bedrooms: '4.5',
      bathrooms: '2',
      mq: '109',
      mq_loggia: '0',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '16.100',
        },
        {
          name: 'Premium',
          price: '38.300',
        },
      ],
      appartments: [
        {
          name: 'TR1.0102',
          scala: 'Treppenh. 1',
          floors: [1],
          basePrice: '1.289.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
      ],
    },
    {
      name: 'M',
      location: 'Riegel',
      typologyCode: 'Z2.5',
      bedrooms: '2.5',
      bathrooms: '1',
      mq: '83',
      mq_loggia: '0',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '9.700',
        },
        {
          name: 'Premium',
          price: '27.800',
        },
      ],
      appartments: [
        {
          name: 'TR1.0103',
          scala: 'Treppenh. 1',
          floors: [1],
          basePrice: '895.000',
          options: ['wc_shower'],
        },
      ],
    },
    {
      name: 'N',
      location: 'Riegel',
      typologyCode: 'Z2.5',
      bedrooms: '2.5',
      bathrooms: '2',
      external_space: 'Balkon',
      mq: '83',
      mq_loggia: '10',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '14.700',
        },
        {
          name: 'Premium',
          price: '36.000',
        },
      ],
      appartments: [
        {
          name: 'TR1.0201',
          scala: 'Treppenh. 1',
          floors: [2],
          basePrice: '895.000',
          options: ['wc_shower'],
        },
        {
          name: 'TR1.0301',
          scala: 'Treppenh. 1',
          floors: [3],
          basePrice: '905.000',
          options: ['wc_shower'],
        },
        {
          name: 'TR1.0401',
          scala: 'Treppenh. 1',
          floors: [4],
          basePrice: '915.000',
          options: ['wc_shower'],
        },
        {
          name: 'TR1.0501',
          scala: 'Treppenh. 1',
          floors: [5],
          basePrice: '925.000',
          options: ['wc_shower'],
          reserved: true,
        },
      ],
    },
    {
      name: 'O',
      location: 'Riegel',
      typologyCode: 'Z4.5',
      bedrooms: '4.5',
      bathrooms: '2',
      external_space: 'Balkon',
      mq: '110',
      mq_loggia: '36',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '16.100',
        },
        {
          name: 'Premium',
          price: '38.300',
        },
      ],
      appartments: [
        {
          name: 'TR1.0202',
          scala: 'Treppenh. 1',
          floors: [2],
          basePrice: '1.299.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
        {
          name: 'TR1.0302',
          scala: 'Treppenh. 1',
          floors: [3],
          basePrice: '1.309.000',
          reserved: true,
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
        {
          name: 'TR1.0402',
          scala: 'Treppenh. 1',
          floors: [4],
          basePrice: '1.319.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
        {
          name: 'TR1.0502',
          scala: 'Treppenh. 1',
          floors: [5],
          basePrice: '1.335.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
      ],
    },
    {
      name: 'P',
      location: 'Riegel',
      typologyCode: 'Z4.5',
      bedrooms: '4.5',
      bathrooms: '2',
      external_space: 'Loggia',
      mq: '137',
      mq_loggia: '8',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '18.300',
        },
        {
          name: 'Premium',
          price: '46.100',
        },
      ],
      appartments: [
        {
          name: 'TR1.0203',
          scala: 'Treppenh. 1',
          floors: [2, 3],
          basePrice: '1.565.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
        {
          name: 'TR1.0403',
          scala: 'Treppenh. 1',
          floors: [4, 5],
          basePrice: '1.585.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
      ],
    },
    {
      name: 'Q',
      location: 'Riegel',
      typologyCode: 'DUPLEX',
      bedrooms: '4.5',
      bathrooms: '2',
      mq: '138',
      mq_loggia: '0',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '18.800',
        },
        {
          name: 'Premium',
          price: '45.800',
        },
      ],
      appartments: [
        {
          name: 'TR2.01',
          scala: 'Treppenh. 2',
          floors: [0, 1],
          basePrice: '1.173.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: false,
        },
      ],
    },
    {
      name: 'R',
      location: 'Riegel',
      typologyCode: 'DUPLEX',
      bedrooms: '3.5',
      bathrooms: '2',
      mq: '169',
      mq_loggia: '0',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '19.600',
        },
        {
          name: 'Premium',
          price: '48.600',
        },
      ],
      appartments: [
        {
          name: 'TR2.02',
          scala: 'Treppenh. 2',
          floors: [0, 1],
          basePrice: '1.436.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
        {
          name: 'TR3.02',
          scala: 'Treppenh. 2',
          floors: [0, 1],
          basePrice: '1.436.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
      ],
    },
    {
      name: 'S',
      location: 'Riegel',
      typologyCode: 'Z3.5',
      bedrooms: '3.5',
      bathrooms: '2',
      external_space: 'Balkon',
      mq: '94',
      mq_loggia: '22',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '15.400',
        },
        {
          name: 'Premium',
          price: '37.000',
        },
      ],
      appartments: [
        {
          name: 'TR2.0201',
          scala: 'Treppenh. 2',
          floors: [2],
          basePrice: '1.059.000',
          options: ['wc_shower'],
        },
        {
          name: 'TR2.0301',
          scala: 'Treppenh. 2',
          floors: [3],
          basePrice: '1.071.000',
          options: ['wc_shower'],
          reserved: true,
        },
        {
          name: 'TR2.0401',
          scala: 'Treppenh. 2',
          floors: [4],
          basePrice: '1.081.000',
          reserved: true,
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
        {
          name: 'TR2.0501',
          scala: 'Treppenh. 2',
          floors: [5],
          basePrice: '1.095.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
      ],
    },
    {
      name: 'T',
      location: 'Riegel',
      typologyCode: 'Z3.5',
      bedrooms: '3.5',
      bathrooms: '2',
      external_space: 'Balkon',
      mq: '92',
      mq_loggia: '14',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '15.900',
        },
        {
          name: 'Premium',
          price: '43.500',
        },
      ],
      appartments: [
        {
          name: 'TR2.0202',
          scala: 'Treppenh. 2',
          floors: [2],
          basePrice: '1.042.000',
          options: ['wc_shower'],
        },
        {
          name: 'TR2.0302',
          scala: 'Treppenh. 2',
          floors: [3],
          basePrice: '1.054.000',
          options: ['wc_shower'],
        },
        {
          name: 'TR2.0402',
          scala: 'Treppenh. 2',
          floors: [4],
          basePrice: '1.064.000',
          options: ['wc_shower'],
        },
        {
          name: 'TR2.0502',
          scala: 'Treppenh. 2',
          floors: [5],
          basePrice: '1.078.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
        {
          name: 'TR3.0202',
          scala: 'Treppenh. 3',
          floors: [2],
          basePrice: '1.042.000',
          options: ['wc_shower'],
        },
        {
          name: 'TR3.0302',
          scala: 'Treppenh. 3',
          floors: [3],
          basePrice: '1.054.000',
          options: ['wc_shower'],
        },
        {
          name: 'TR3.0402',
          scala: 'Treppenh. 3',
          floors: [4],
          basePrice: '1.064.000',
          options: ['wc_shower'],
        },
        {
          name: 'TR3.0502',
          scala: 'Treppenh. 3',
          floors: [5],
          basePrice: '1.078.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
      ],
    },
    {
      name: 'U',
      location: 'Riegel',
      typologyCode: 'DUPLEX',
      bedrooms: '4.5',
      bathrooms: '2',
      mq: '151',
      mq_loggia: '0',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '18.900',
        },
        {
          name: 'Premium',
          price: '47.200',
        },
      ],
      appartments: [
        {
          name: 'TR3.01',
          scala: 'Treppenh. 2',
          floors: [0, 1],
          basePrice: '1.286.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
        {
          name: 'TR4.01',
          scala: 'Treppenh. 4',
          floors: [0, 1],
          basePrice: '1.286.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
      ],
    },
    {
      name: 'W',
      location: 'Riegel',
      typologyCode: 'Z2.5',
      bedrooms: '2.5',
      bathrooms: '1',
      mq: '92',
      mq_loggia: '0',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '10.200',
        },
        {
          name: 'Premium',
          price: '23.100',
        },
      ],
      appartments: [
        {
          name: 'TR4.0102',
          scala: 'Treppenh. 4',
          floors: [1],
          basePrice: '951.000',
          options: ['wc_shower'],
        },
      ],
    },
    {
      name: 'V',
      location: 'Riegel',
      typologyCode: 'Z4.5',
      bedrooms: '4.5',
      bathrooms: '2',
      external_space: 'Balkon',
      mq: '106',
      mq_loggia: '22',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '16.500',
        },
        {
          name: 'Premium',
          price: '44.600',
        },
      ],
      appartments: [
        {
          name: 'TR3.0201',
          scala: 'Treppenh. 3',
          floors: [2],
          basePrice: '1.190.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
        {
          name: 'TR3.0301',
          scala: 'Treppenh. 3',
          floors: [3],
          basePrice: '1.202.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
        {
          name: 'TR3.0401',
          scala: 'Treppenh. 3',
          floors: [4],
          basePrice: '1.212.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
        {
          name: 'TR3.0501',
          scala: 'Treppenh. 3',
          floors: [5],
          basePrice: '1.225.000',
          reserved: true,
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
        {
          name: 'TR4.0201',
          scala: 'Treppenh. 4',
          floors: [2],
          basePrice: '1.190.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
        },
        {
          name: 'TR4.0301',
          scala: 'Treppenh. 4',
          floors: [3],
          basePrice: '1.202.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
        {
          name: 'TR4.0401',
          scala: 'Treppenh. 4',
          floors: [4],
          basePrice: '1.212.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
        {
          name: 'TR4.0501',
          scala: 'Treppenh. 4',
          floors: [5],
          basePrice: '1.225.000',
          options: ['parking', 'electric_charging', 'wc_shower'],
          reserved: true,
        },
      ],
    },
    {
      name: 'X',
      location: 'Riegel',
      typologyCode: 'Z3.5',
      bedrooms: '3.5',
      bathrooms: '2',
      external_space: 'Balkon',
      mq: '90',
      mq_loggia: '13',
      configurations: [
        {
          name: 'Easy',
          price: '0',
        },
        {
          name: 'Comfort',
          price: '15.800',
        },
        {
          name: 'Premium',
          price: '43.400',
        },
      ],
      appartments: [
        {
          name: 'TR4.0202',
          scala: 'Treppenh. 4',
          floors: [2],
          basePrice: '963.000',
          options: ['wc_shower'],
        },
        {
          name: 'TR4.0302',
          scala: 'Treppenh. 4',
          floors: [3],
          basePrice: '975.000',
          options: ['wc_shower'],
        },
        {
          name: 'TR4.0402',
          scala: 'Treppenh. 4',
          floors: [4],
          basePrice: '985.000',
          options: ['wc_shower'],
        },
        {
          name: 'TR4.0502',
          scala: 'Treppenh. 4',
          floors: [5],
          basePrice: '998.000',
          options: ['wc_shower'],
          reserved: true,
        },
      ],
    },
  ],
  furnishingOptions: [
    {
      name: 'Easy',
      price: 0,
      text:
        ' Ebenmässiges Holz im Wohnbereich trifft auf kühle Moderne in den Badezimmern. Die Kombination von dezenten Farbtönen und starken Kontrasten verleihen Ihrer Wohnung einen individuellen Charakter.',
      finishings: [
        {
          name: 'Parkett',
          materials: [
            {
              name: 'GOLDEN FOREST',
              code: 'goldenforest',
            },
            {
              name: 'HONEY FOREST',
              code: 'honeyforest',
            },
            {
              name: 'NORTHERN FOREST',
              code: 'northernforest',
            },
            {
              name: 'BLACK FOREST',
              code: 'blackforest',
            },
          ],
        },
        {
          name: 'Bad',

          materials: [
            {
              name: 'FOGGY GREY',
              code: 'foggygray',
            },
            {
              name: 'ICY GREY',
              code: 'icygray',
            },
            {
              name: 'RAINY GREY',
              code: 'rainygray',
            },
            {
              name: 'WARM GREY',
              code: 'warm gray',
            },
          ],
        },
      ],
    },
    {
      name: 'Comfort',
      price: 0,
      text:
        'Naturverbundene Keramikplatten und Parkett mit lebendiger Holzmaserung sind die charakteristischen Elemente dieser Designlinie. Geradliniges Design und eine komfortable Ausstattung verwandeln die Bäder in einen Ort zum Wohlfühlen und Entspannen.',
      finishings: [
        {
          name: 'Parkett',
          materials: [
            {
              name: 'GOLDEN FOREST',
              code: 'goldenforest',
            },
            {
              name: 'ANCIENT FOREST',
              code: 'ancientforest',
            },
            {
              name: 'NORTHERN FOREST',
              code: 'northernforest',
            },
            {
              name: 'BLACK FOREST',
              code: 'blackforest',
            },
          ],
        },
        {
          name: 'Bad',

          materials: [
            {
              name: 'INDIUM',
              code: 'indium',
            },
            {
              name: 'CHROMIUM',
              code: 'chromium',
            },
            {
              name: 'TITANIUM',
              code: 'titanium',
            },
            {
              name: 'PLATINUM',
              code: 'platinum',
            },
          ],
        },
      ],
    },
    {
      name: 'Premium',
      price: 0,
      text:
        ' Die zeitlose und weitläufige Eleganz des Holzes und die luxuriöse Erscheinung der Oberflächen erzeugen ein Wohnambiente auf höchstem Niveau. Eine elegante und grosszügige Ausstattung der Bäder vervollständigt das exklusive Lebensgefühl.',
      finishings: [
        {
          name: 'Parkett',
          materials: [
            {
              name: 'WILD FOREST',
              code: 'wildforest',
            },
            {
              name: 'ANCIENT FOREST',
              code: 'ancientforest',
            },
            {
              name: 'NORTHERN FOREST',
              code: 'northernforest',
            },
            {
              name: 'BLACK FOREST',
              code: 'blackforest',
            },
          ],
        },
        {
          name: 'Bad',

          materials: [
            {
              name: 'PEARL DUST',
              code: 'peaarldust',
            },
            {
              name: 'SILVER DUST',
              code: 'silverdust',
            },
            {
              name: 'DESERT DUST',
              code: 'desert dust',
            },
            {
              name: 'CASHMERE DUST',
              code: 'cachemire dust',
            },
          ],
        },
      ],
    },
  ],
};

export const baseUrl = '';
export const imageUrl = 'https://tender-homeconfigurator.lokstadt.ch/img/';
export default data;
