import React from 'react';
import FurnishingBox from '../components/FurnishingBox';
import OptionBox from '../components/OptionBox';
import Nav from '../components/Nav';
import PropTypes from 'prop-types';
import CustomizeImageContainer from '../components/CustomizeImageContainer';

const Customize = ({
  furnishingOptions,
  typologyCode,
  planimetryCode,
  appartmentName,
  isAppartmentNameValid,
}) => {
  const [activeFurnishing, setActiveFurnishing] = React.useState({
    furnishingName: furnishingOptions[0].name,
    finishingName: furnishingOptions[0].finishings[0].name,
    materialName: furnishingOptions[0].finishings[0].materials[0].name,
  });

  const [parkett, setParkett] = React.useState({
    furnishing: furnishingOptions[0].name,
    finishingName: 'Parkett',
    materialName: furnishingOptions[0].finishings[0].materials[0].name,
  });
  const [bad, setBad] = React.useState({
    furnishing: furnishingOptions[0].name,
    finishingName: 'Bad',
    materialName: furnishingOptions[0].finishings[1].materials[0].name,
  });

  const setOptionBox = (finishingName, material) => {
    const finishing = furnishingOptions
      .find((box) => box.name === activeFurnishing.furnishingName)
      .finishings.find((item) => item.name === finishingName);
    const materialName = finishing.materials.find((i) => i.name === material)
      .name;
    setActiveFurnishing({
      ...activeFurnishing,
      finishingName,
      materialName,
    });

    if (finishingName === 'Parkett')
      setParkett({
        ...parkett,
        finishingName,
        furnishing: activeFurnishing.furnishingName,
        materialName,
      });
    else
      setBad({
        ...bad,
        finishingName,
        furnishing: activeFurnishing.furnishingName,
        materialName,
      });
  };

  const imageSrc = `${process.env.PUBLIC_URL}/img/furnishingOptions/${activeFurnishing.finishingName}/${activeFurnishing.furnishingName}/${activeFurnishing.furnishingName}_${activeFurnishing.materialName}.jpg`;

  return (
    <>
      <Nav
        typologyCode={typologyCode}
        planimetryCode={planimetryCode}
        isAppartmentNameValid={isAppartmentNameValid}
        appartmentName={appartmentName}
      />

      <div className="main-container">
        <div className="customize-container">
          <div className="furnishings">
            <h5>
              WÄHLEN SIE DIE AUSSTATTUNG <br />
              IHRER WOHNUNG AUS
            </h5>

            {furnishingOptions.map((box, idx) => (
              <FurnishingBox
                key={box.name}
                name={box.name}
                rate={box.rate}
                price={box.price}
                isActive={activeFurnishing.furnishingName === box.name}
                onSelect={() => {
                  setParkett({
                    furnishing: box.name,
                    finishingName: 'Parkett',
                    materialName: box.finishings[0].materials[0].name,
                  });
                  setBad({
                    furnishing: box.name,
                    finishingName: 'Bad',
                    materialName: box.finishings[1].materials[0].name,
                  });
                  setActiveFurnishing({
                    furnishingName: box.name,
                    materialName: box.finishings[0].materials[0].name,
                    finishingName: box.finishings[0].name,
                  });
                }}
              />
            ))}
          </div>
          <CustomizeImageContainer
            activeFurnishing={activeFurnishing}
            typologyCode={typologyCode}
            planimetryCode={planimetryCode}
            src={imageSrc}
            selected={{ parkett, bad }}
            isAppartmentNameValid={isAppartmentNameValid}
            appartmentName={appartmentName}
          />

          <div className="options-container">
            {activeFurnishing.finishingName &&
              typeof furnishingOptions.find(
                (box) => box.name === activeFurnishing.furnishingName
              ).finishings !== 'undefined' &&
              furnishingOptions
                .find((box) => box.name === activeFurnishing.furnishingName)
                .finishings.map((finishing, idx) => {
                  return (
                    <OptionBox
                      key={idx}
                      activeFurnishing={activeFurnishing}
                      selected={{ parkett, bad }}
                      selectedMaterial={
                        finishing.name === 'Parkett' ? parkett : bad
                      }
                      name={finishing.name}
                      materials={finishing.materials}
                      setOptionBox={(material) =>
                        setOptionBox(finishing.name, material)
                      }
                    />
                  );
                })}
          </div>
        </div>
      </div>
    </>
  );
};

Customize.defaultProps = {
  isAppartmentNameValid: false,
  appartmentName: '',
};
Customize.propTypes = {
  furnishingOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      rate: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
      finishings: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          materials: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
            })
          ),
        })
      ),
    })
  ).isRequired,
  typologyCode: PropTypes.string.isRequired,
  planimetryCode: PropTypes.string.isRequired,
  appartmentName: PropTypes.string,
  isAppartmentNameValid: PropTypes.bool,
};

export default Customize;
