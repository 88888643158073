import React from 'react';
import PropTypes from 'prop-types';

const FurnishingDescription = ({ name, text }) => {
  return (
    <div className={`customize-container furnishings ${name}`}>
      <div className="furnishing-box active">
        <h5>AUSSTATTUNG</h5>
        <h2>{name}</h2>
        <hr></hr>
        <div className="text-box">{text}</div>
      </div>
    </div>
  );
};

FurnishingDescription.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default FurnishingDescription;
