import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import {
  getAllDays,
  getPaginatedItems,
  getDateObject,
  getAllHours,
} from '../../datefunctions';
import Weeks from './Weeks';
import Hours from './Hours';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import Dialogue from './Dialogue';

import { getRooms, getPDFfilename, padDigits } from '../../utils';
import { API_URL, PDF_URL } from '../../config';
import _ from 'lodash';

const SelectedDay = styled.p`
  display: flex;
  width: 100%;
  height: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  font-family: mr-eaves-modern, sans-serif;
  color: #000;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  margin: 0;
  border-bottom: 1px solid #d1d1d1;
`;

const Submit = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0rem auto 2rem auto;
  padding: 1rem 2.5rem;
  background-color: #d1d1d1;
  font-family: "Swis721 Cn BT";
  color: #fff;
  font-size: .9rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  cursor: not-allowed;
  transition: background .3s ease-in;
  }
  ${(props) =>
    props.active &&
    `background-color: #d97c50;
      cursor: pointer;
      &:hover{
        background-color: rgba(217,124,80,.7);
    }
`}
`;
//const ApiURI = 'http://localhost:8010/proxy/';

//const ApiURI = 'http://localhost:8010/proxy/tendercalendarform-go';
// const ApiURI =
//   'http://vorschau:bs-vorhang@www.stage.lokstadt.ch/de-ch/tendercalendarform-go';

export async function submitData(input) {
  try {
    const urlData = new URLSearchParams({
      calendar_require: 1,
      API_POST: 1,
      pdf: input.pdf,
      calendar_anrede: input.calendar_anrede,
      calendar_starttime: input.calendar_starttime,
      calendar_endtime: input.calendar_endtime,
      'calendar_rooms[]': input.calendar_rooms,
      calendar_vorname: input.calendar_vorname,
      calendar_nachname: input.calendar_nachname,
      calendar_strasse: input.calendar_strasse,
      calendar_plz: input.calendar_plz,
      calendar_ort: input.calendar_ort,
      calendar_email: input.calendar_email,
      calendar_telefon: input.calendar_telefon,
      calendar_bemerkungen: input.calendar_bemerkungen,
      calendar_visitors: input.calendar_visitors,
      id: 1099,
    });

    const result = await fetch(API_URL + 'setAppointment/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: urlData,
    })
      .then((resp) => {
        console.log(resp);
        return resp.text();
      })

      .catch(function (e) {
        console.log('another error happened:' + e.message);
      });

    return result;
  } catch (ex) {
    return null;
  }
}

const AppointmentPicker = ({ formData, done, typologyCode, pdfData }) => {
  // console.log(formData);

  let allDays = getAllDays(35);

  //console.log(allDays);
  // Number of days per page
  const numberOfDaysPerPage = 7;
  // days to be shown in each page
  // if all the items of the first week are inactive then remove them from the array
  if (
    getPaginatedItems(allDays, 1, numberOfDaysPerPage).every(
      (item) => item.active === false
    )
  ) {
    allDays.splice(0, 7);
  }
  const [days, setDays] = useState(
    getPaginatedItems(allDays, 1, numberOfDaysPerPage)
  );

  //console.log(days);

  // selected day, initialy the first one of the active ones
  const [selectedDay, setSelectedDay] = useState(
    days.filter((itm) => itm.active === true)[0]
  );
  // current page in the pagination
  const [currentDayPage, setCurrentDayPage] = useState(1);
  const [confirm, setConfim] = useState(false);

  const [showError, setShowError] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({ hours: null });

  // handles selecting the week
  const handleWeekSelect = (page) => {
    // sets the selected event initialy
    setSelectedEvent({ hours: null });
    const daysInCurrentPage = getPaginatedItems(
      allDays,
      page,
      numberOfDaysPerPage
    );
    setDays(daysInCurrentPage);
    setCurrentDayPage(page);
    // get the first active item of the days list as the deafult selected day
    setSelectedDay(daysInCurrentPage.filter((itm) => itm.active === true)[0]);
  };

  const handleHoursSelect = (hours) => {
    setSelectedEvent({ hours });
  };

  const handleDaySelect = (day) => {
    setSelectedEvent({ hours: null });
    setSelectedDay(day.item);
  };

  // array containing the available hours of appointments for each day
  const [data, setdata] = React.useState([]);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  // const [hours, setHours] = useState(null);
  // let hours = null;

  const now = new Date(Date.now());
  const nextDate = new Date(now).setDate(now.getDate() + allDays.length);

  const url = `${API_URL}getAppoitments?&start=${Math.floor(
    now / 1000
  )}&end=${Math.floor(nextDate / 1000)}`;

  const [loading, setLoading] = React.useState(false);
  const isLoading = useRef(false);
  const [pdf, setPDF] = useState(null);

  useEffect(() => {
    if (pdf === null) {
      getPDFfilename(PDF_URL, pdfData).then((l) => {
        setPDF('php/files/' + l.json.filename);
      });
    }
  }, [pdf, pdfData]);

  useEffect(() => {
    isLoading.current = true;
    let unmounted = false;
    const fetchData = async () => {
      setLoading(true);
      try {
        await fetch(url)
          .then((resp) => resp.json())
          .then((d) => {
            if (!unmounted) {
              // setdata(data);
              // console.log(d);

              if (!data.length) setdata(d);

              setLoading(false);
              isLoading.current = false;
              unmounted = true;
              // @ts-ignore
            }
          })
          .catch(function (e) {
            if (!unmounted) {
              // setdata([]);
              setLoading(false);
              isLoading.current = false;

              console.log('another error happened:' + e.message);
            }
          });
      } catch (error) {
        console.log('use query');
      }
    };
    if (!unmounted) fetchData();
    return function () {
      unmounted = true;
    };
  }, []);

  var newHours = [];
  if (typeof data.freebusys !== 'undefined') {
    newHours = getAllHours(
      data.freebusys
        // .filter((item) => item.free === true)
        .map((item) => ({
          // data.freebusys.map((item) => ({
          start: getDateObject(item.start.replace(/-/g, '/')),
          end: getDateObject(item.end.replace(/-/g, '/')),
          free: item.free,
        })),
      selectedDay.day,
      selectedDay.month,
      false,
      15
    );
  }

  // const newAllDays = allDays.map((day) => {
  //   const hours =
  //     day.active && data && typeof data.freebusys !== 'undefined'
  //       ? getAllHours(
  //           data.freebusys
  //             // .filter((item) => item.free === true)
  //             .map((item) => ({
  //               // data.freebusys.map((item) => ({
  //               start: getDateObject(item.start),
  //               end: getDateObject(item.end),
  //               free: item.free,
  //             })),
  //           day.day,
  //           day.month,
  //           false,
  //           15
  //         )
  //       : [];
  //   return {
  //     ...day,
  //     hours,
  //     active: hours.length > 0,
  //   };
  // });
  ///

  // Zeitfenster 1: 8:00 - 9:00;
  // Zeitfenster 2: 9:30 - 10:30;
  // Zeitfenster 3: 11:00 - 12:00;
  // Zeitfenster 4: 13:30 - 14:30;
  // Zeitfenster 5: 15:00 - 16:00;
  // Zeitfenster 6: 16:30 - 17:30;
  // const validTimeSloys = [
  //   '08:00-09:00',
  //   '09:30-10:30',
  //   '11:00-12:00',
  //   '13:30-14:30',
  //   '15:00-16:00',
  //   '16:30-17:30',
  // ];

  // let validHours = newHours;
  // if (newHours.length) {
  //   validHours = validTimeSloys.map((item) => {
  //     const divided = item.split('-');
  //     const filetedHours = newHours.filter((itm) => {
  //       const splitedHours = itm.hours.split('-');

  //       return splitedHours[0] === divided[0] || splitedHours[1] === divided[1];
  //     });

  //     return {
  //       hours: item,
  //       day: filetedHours[0].day,
  //       month: filetedHours[0].month,
  //       year: filetedHours[0].year,
  //       free:
  //         filetedHours.filter((i) => i.free === true).length > 0 ? true : false,
  //     };
  //   });
  // }

  // if (!hours || hours !== newHours)
  //   hours =
  //     data &&
  //     typeof data.freebusys !== 'undefined' &&
  //     data.freebusys.filter((item) => item.free).length > 0
  //       ? newHours
  //       : [];

  return loading ? (
    <div className="box-wrapper">
      <Loader
        type="TailSpin"
        color="#d1d1d1"
        height={100}
        width={100}
        timeout={3000} //3 secs
      />
    </div>
  ) : confirm ? (
    <>
      <Dialogue
        open={confirm}
        title="
    "
        logoClass="question_logo"
        subtitle=" Herzlichen Dank für Ihr Interesse an unserem Wohnangebot im Tender in
      der Lokstadt. Sie erhalten in Kürze die Bestätigung Ihres
      Beratungstermin im Tender Showroom per E-Mail. Wir freuen uns darauf,
      Sie schon bald persönlich im Tender Showroom begrüssen zu dürfen! Urbane
      Grüsse aus der Lokstadt Ihr Implenia Verkaufsteam"
        dialogueButtons={[
          {
            text: 'OK',
            type: 'yes',
            handleClick: () => {
              setConfim(false);
              setdata(null);
              done();
            },
          },
        ]}
      />
      <div className="confirm"></div>
    </>
  ) : showError ? (
    <Dialogue
      open={showError}
      title="Fehler
    "
      logoClass="question_logo"
      subtitle="Es ist ein Fehler aufgetreten. Versuchen Sie es erneut"
      dialogueButtons={[
        {
          text: 'OK',
          type: 'no',
          handleClick: () => setShowError(false),
        },
      ]}
    />
  ) : (
    <div className="box">
      <div className="box-wrapper">
        <Weeks
          loading={isLoading}
          days={days}
          nextPage={
            currentDayPage < Math.ceil(allDays.length / numberOfDaysPerPage)
              ? currentDayPage + 1
              : 0
          }
          prevPage={currentDayPage > 1 ? currentDayPage - 1 : 0}
          daySelect={handleDaySelect}
          handleWeekSelect={handleWeekSelect}
          selectedDay={selectedDay.day}
        />
        <div className="selected-day">
          {new Date(selectedDay.date).toLocaleString('de-CH', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </div>
        <Hours
          selectedDay={selectedDay}
          hoursSelect={handleHoursSelect}
          hours={newHours}
          selectedEvent={selectedEvent}
          loading={loading}
        />
        <Submit
          role="button"
          tabindex="0"
          active={selectedEvent.hours !== null}
          onClick={() => {
            if (selectedEvent.hours !== null) setIsDialogOpen(true);
          }}
        >
          Absenden
        </Submit>
        <Dialogue
          open={isDialogOpen}
          title="Buchungsbestätigung"
          logoClass="question_logo"
          subtitle="Bitte bestätigen Sie Ihren Beratungstermin"
          date={`${padDigits(selectedDay.day, 2)}.${padDigits(
            selectedDay.month,
            2
          )}.${selectedDay.year} - ${selectedEvent.hours} Uhr`}
          dialogueButtons={[
            {
              text: 'Ja',
              type: 'yes',

              handleClick: () => {
                // const selectedHoursText = `${selectedDay.toLocaleDateString(
                //   'ch-CH',
                //   {
                //     weekday: 'long',
                //     month: 'long',
                //     day: 'numeric',
                //   }
                // )} `;
                const hours = selectedEvent.hours.split('-');

                const submittedData = {
                  calendar_starttime: `${selectedDay.year}-${padDigits(
                    selectedDay.month,
                    2
                  )}-${padDigits(selectedDay.day, 2)} ${hours[0]}`,
                  calendar_endtime: `${selectedDay.year}-${padDigits(
                    selectedDay.month,
                    2
                  )}-${padDigits(selectedDay.day, 2)} ${hours[1]}`,
                  calendar_rooms: getRooms(typologyCode),
                  calendar_anrede: formData.sex,
                  calendar_vorname: formData.firstName,
                  calendar_nachname: formData.lastName,
                  calendar_strasse: formData.address,
                  calendar_plz: formData.postcode,
                  calendar_ort: formData.location,
                  calendar_email: formData.email,
                  calendar_telefon: formData.phone,
                  calendar_bemerkungen: formData.note,
                  calendar_visitors: formData.visitors,
                  pdf: 'https://tender-homeconfigurator.lokstadt.ch/' + pdf,
                };

                setLoading(true);

                submitData(submittedData).then((e) => {
                  setLoading(false);
                  console.log('response is', e);

                  if (e === 'OK') {
                    setConfim(true);
                  } else {
                    setShowError(true);
                  }
                });

                console.log(selectedDay, selectedEvent, submittedData);
              },
            },
            {
              text: 'Nein',
              type: 'no',
              handleClick: () => setIsDialogOpen(false),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default AppointmentPicker;
