import React from 'react';

import PropTypes from 'prop-types';

const PlanimetryBoxExtended = ({
  size,
  name,
  PlanimetryMQ,
  bader,
  loggia,
  loggiaType,
  schlafzimmer,
}) => {
  return (
    <div className="PlanimetryBox">
      <div className="planimetry-info">
        <h5 className="size">{size}</h5>
        <h2>{name}</h2>
        <hr></hr>
        <h5 className="mq">
          {PlanimetryMQ} m<sup>2</sup>
        </h5>
        <div className="services">
          <div className="bath">
            <h5>BÄDER</h5>
            <h5 className="quantity">{bader}</h5>
          </div>
          {/* <div className="rooms">
            <h5>SCHLAFZIMMER</h5>
            <h5 className="quantity">{schlafzimmer}</h5>
          </div> */}

          {loggia !== '0' && (
            <div className="loggia">
              <h5>{loggiaType}</h5>
              <h5 className="quantity">
                {loggia} m<sup>2</sup>
              </h5>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PlanimetryBoxExtended.defaultProps = {
  schlafzimmer: '',
};
PlanimetryBoxExtended.propTypes = {
  size: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  PlanimetryMQ: PropTypes.string.isRequired,
  bader: PropTypes.string.isRequired,
  schlafzimmer: PropTypes.string,
};

export default PlanimetryBoxExtended;
