import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const Nav = ({
  typologyCode,
  planimetryCode,
  customization,
  isAppartmentNameValid,
  appartmentName,
}) => {
  return (
    <nav>
      <ul>
        {!isAppartmentNameValid && (
          <li>
            <NavLink
              activeClassName="active"
              className="nav--home"
              exact
              to="/"
            ></NavLink>
          </li>
        )}
        {isAppartmentNameValid ? null : typologyCode ? (
          <li>
            <NavLink
              activeClassName={!planimetryCode ? 'active' : ''}
              className="nav--planimetry"
              to={`/${typologyCode}`}
            >
              <span className="page-number">1</span>{' '}
              <span className="lg-hidden">WÄHLEN SIE IHRE WOHNUNG</span>
            </NavLink>
          </li>
        ) : (
          <li>
            <div className="nav--planimetry inactive" to="/">
              <span className="page-number">1</span>
              <span className="lg-hidden">WÄHLEN SIE IHRE WOHNUNG</span>
            </div>
          </li>
        )}
        {typologyCode && planimetryCode ? (
          <li
            style={
              isAppartmentNameValid && appartmentName ? { flex: '0 0 50%' } : {}
            }
          >
            <NavLink
              activeClassName={!customization ? 'active' : ''}
              className="nav--customize"
              to={`/${typologyCode}/${planimetryCode}${
                isAppartmentNameValid && appartmentName
                  ? `?name=${appartmentName}`
                  : ''
              }`}
            >
              <span className="page-number">
                {isAppartmentNameValid ? 1 : 2}
              </span>{' '}
              <span className="lg-hidden">WÄHLEN SIE IHRE AUSSTATTUNG</span>
            </NavLink>
          </li>
        ) : (
          <li>
            <div className="nav--planimetry inactive" to="/">
              <span className="page-number">2</span>
              <span className="lg-hidden">WÄHLEN SIE IHRE AUSSTATTUNG</span>
            </div>
          </li>
        )}
        {typologyCode && planimetryCode && customization ? (
          <li
            style={
              isAppartmentNameValid && appartmentName ? { flex: '0 0 50%' } : {}
            }
          >
            <NavLink
              activeClassName="active"
              className="nav--done"
              to={`/${typologyCode}/${planimetryCode}/${
                customization.furnishingName
              }-${
                customization.finsihingNames[0]
              }-${customization.materialNames[0].split(' ').join('_')}___${
                customization.finsihingNames[1]
              }-${customization.materialNames[1].split(' ').join('_')}${
                isAppartmentNameValid && appartmentName > 0
                  ? `___${appartmentName}`
                  : ''
              }`}
            ></NavLink>
          </li>
        ) : (
          <li
            style={
              isAppartmentNameValid && appartmentName ? { flex: '0 0 50%' } : {}
            }
          >
            <div className="nav--done inactive" to="/"></div>
          </li>
        )}{' '}
      </ul>
    </nav>
  );
};

Nav.defaultProps = {
  typologyCode: '',
  planimetryCode: '',
  isAppartmentNameValid: false,
  appartmentName: 0,
};

Nav.propTypes = {
  typologyCode: PropTypes.string,
  planimetryCode: PropTypes.string,
  customization: PropTypes.shape({
    furnishingName: PropTypes.string.isRequired,
    finsihingNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    materialNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    isAppartmentNameValid: PropTypes.bool,
    appartmentName: PropTypes.arrayOf(PropTypes.number),
  }),
};

export default Nav;
