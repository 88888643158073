import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';

const CustomizeImageContainer = ({
  src,
  typologyCode,
  planimetryCode,
  activeFurnishing,
  selected,
  isAppartmentNameValid,
  appartmentName,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setImageLoaded(false);
    const img = new Image();
    img.src = src;
    img.onload = () => setImageLoaded(true);
  }, [src]);

  return (
    <div
      className="customize-image-container"
      name={activeFurnishing.finishingName}
      style={
        imageLoaded
          ? {
              backgroundColor: '#e2e2e2',
              backgroundImage: `url('${src}')`,
            }
          : { backgroundColor: '#e2e2e2' }
      }
    >
      {
        <div
          style={{
            display: 'flex',
            margin: '0',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {!imageLoaded && (
            <Loader
              style={{ textAlign: 'center' }}
              type="TailSpin"
              color="#666766"
              height={100}
              width={100}
              //   timeout={3000} //3 secs
            />
          )}
        </div>
      }
      {selected.parkett && selected.bad && (
        <Link
          className="primary-button"
          to={`/${typologyCode}/${planimetryCode}/${
            activeFurnishing.furnishingName
          }-${
            selected.parkett.finishingName
          }-${selected.parkett.materialName.split(' ').join('_')}
___${selected.bad.finishingName}-${selected.bad.materialName
            .split(' ')
            .join('_')}${isAppartmentNameValid ? `___${appartmentName}` : ''}`}
        >
          Auswahl bestätigen
        </Link>
      )}
    </div>
  );
};

CustomizeImageContainer.propTypes = {
  src: PropTypes.string.isRequired,
  typologyCode: PropTypes.string.isRequired,
  planimetryCode: PropTypes.string.isRequired,
  activeFurnishing: PropTypes.shape({
    furnishingName: PropTypes.string.isRequired,
    finishingName: PropTypes.string.isRequired,
    materialName: PropTypes.string.isRequired,
  }).isRequired,

  selected: PropTypes.shape({
    parkett: PropTypes.shape({
      furnishingName: PropTypes.string,
      finishingName: PropTypes.string.isRequired,
      materialName: PropTypes.string.isRequired,
    }),
    bad: PropTypes.shape({
      furnishingName: PropTypes.string,
      finishingName: PropTypes.string.isRequired,
      materialName: PropTypes.string.isRequired,
    }),
  }).isRequired,
  isAppartmentNameValid: PropTypes.bool,
  appartmentName: PropTypes.string,
};

export default CustomizeImageContainer;
