import React from 'react';
import {
  BrowserRouter as Router,
  //HashRouter as Router,
  withRouter,
  Route,
} from 'react-router-dom';

import './scss/style.scss';

import Header from './components/Header';
import Footer from './components/Footer';

import Typology from './pages/Typology';
import Planimetry from './pages/Planimetry';
import Customize from './pages/Customize';
import Done from './pages/Done';
import Disclaimer from './pages/Disclaimer';
import Modal from 'react-modal';
import data from './data';
import { convertToPrice, getRate, convertToCurrencyValue } from './utils';
import _ from 'lodash';

import ReactGA from 'react-ga';

Modal.setAppElement('#root');
if (sessionStorage.getItem('referrer') === null) {
  const urlParams = new URLSearchParams(window.location.search);
  const ref = urlParams.get('refid');
  const referrer = ref
    ? `${document.referrer}#${ref}`
    : ref === 'sales-kit'
    ? 'https://tender-saleskit.lokstadt.ch/'
    : document.referrer;
  if (document.referrer) sessionStorage.setItem('referrer', referrer);
}

function App() {
  const [savedRef, setReferrer] = React.useState(null);
  ReactGA.initialize('UA-115777990-22');
  const urlParams = new URLSearchParams(window.location.search);

  if (savedRef === null) {
    const ref = urlParams.get('refid');
    const referrer = ref
      ? `${document.referrer}#${ref}`
      : ref === 'sales-kit'
      ? 'https://tender-saleskit.lokstadt.ch/'
      : document.referrer;

    if (referrer) setReferrer(referrer);
  }

  const [showHeaderBackLink, setShowHeaderBacklink] = React.useState(true);

  return (
    <Router basename="">
      <div className="App">
        <Header savedRef={savedRef} showHeaderBackLink={showHeaderBackLink} />

        <Route
          path="/"
          render={withRouter((p) => {
            ReactGA.pageview(p.location.pathname);

            const typlogies = data.typlogies.map((typology) => {
              const allPricesForTypology = [].concat(
                ...data.planimetries
                  .filter((plan) => plan.typologyCode === typology.code)
                  .map((i) =>
                    i.appartments.map((i) => convertToPrice(i.basePrice))
                  )
              );
              return {
                ...typology,
                price_range: `ca. CHF ${convertToCurrencyValue(
                  _.min(allPricesForTypology)
                )} - ${convertToCurrencyValue(_.max(allPricesForTypology))}`,
              };
            });
            return <Typology typologies={typlogies} />;
          })}
          exact
        />
        <Route
          path="/:typologyCode"
          exact
          render={withRouter((p) => {
            ReactGA.pageview(p.location.pathname);

            return p.match.params.typologyCode === 'disclaimer' ? (
              <Disclaimer />
            ) : (
              <Planimetry
                typologyCode={p.match.params.typologyCode}
                planimetries={data.planimetries.filter(
                  (planimetry) =>
                    planimetry.typologyCode === p.match.params.typologyCode
                )}
                typology={data.typlogies.find(
                  (typo) => typo.code === p.match.params.typologyCode
                )}
              />
            );
          })}
        />
        <Route
          path="/:typologyCode/:planimetryCode"
          exact
          render={withRouter((p) => {
            ReactGA.pageview(p.location.pathname);
            const urlParams = new URLSearchParams(window.location.search);
            const appartmentName = urlParams.get('name');
            const planimetry = data.planimetries.find(
              (plan) => plan.name === p.match.params.planimetryCode
            );
            const isAppartmentNameValid =
              planimetry.appartments.filter(
                (itm) => itm.name === appartmentName //true
              ).length !== 0;

            if (isAppartmentNameValid) {
              sessionStorage.setItem('appartment', appartmentName);
            }

            const minPrice =
              !appartmentName || !isAppartmentNameValid
                ? _.min(
                    planimetry.appartments.map((itm) =>
                      convertToPrice(itm.basePrice)
                    )
                  )
                : _.min(
                    planimetry.appartments
                      .filter((itm) => itm.name === appartmentName)
                      .map((itm) => convertToPrice(itm.basePrice))
                  );

            const configurations = planimetry.configurations.map((item) => ({
              ...item,
              price: convertToPrice(item.price) + minPrice,
              rate: getRate((convertToPrice(item.price) + minPrice).toString()),
            }));

            const furnishingOptions = data.furnishingOptions.map((option) => ({
              ...option,
              price: convertToCurrencyValue(
                configurations
                  .find((cust) => cust.name === option.name)
                  .price.toString()
              ),
              rate: convertToCurrencyValue(
                configurations
                  .find((cust) => cust.name === option.name)
                  .rate.toString()
              ),
            }));

            if (isAppartmentNameValid) setShowHeaderBacklink(false);

            return (
              <Customize
                planimetryCode={p.match.params.planimetryCode}
                typologyCode={p.match.params.typologyCode}
                furnishingOptions={furnishingOptions}
                configurations={configurations}
                appartmentName={appartmentName}
                isAppartmentNameValid={isAppartmentNameValid}
              />
            );
          })}
        />
        <Route
          path="/:typologyCode/:planimetryCode/:customization"
          render={withRouter((p) => {
            ReactGA.pageview(p.location.pathname);

            const customizationDataArray = p.match.params.customization.split(
              '___'
            );
            const parkettArray = customizationDataArray[0].split('-');
            const badArray = customizationDataArray[1].split('-');
            const appartmentName = customizationDataArray[2];
            let customization = [];
            // if (customizationDataArray.length === 3)
            customization = {
              furnishingName: parkettArray[0],
              finsihingNames: [parkettArray[1], badArray[0]],
              materialNames: [
                parkettArray[2].split('_').join(' '),
                badArray[1].split('_').join(' '),
              ],
              furnishingText: data.furnishingOptions.find(
                (itm) => itm.name === parkettArray[0]
              ).text,
            };

            const planimety = data.planimetries.find(
              (item) => item.name === p.match.params.planimetryCode
            );

            return (
              <Done
                planimetryCode={p.match.params.planimetryCode}
                planimetry={{
                  ...planimety,
                  appartments: planimety.appartments.map((i) => ({
                    ...i,
                    options: i.options.map((op) => ({
                      ...data.packages.find((ad) => ad.code === op),
                    })),
                  })),
                }}
                typologyCode={p.match.params.typologyCode}
                customization={customization}
                typology={data.typlogies.find(
                  (typo) => typo.code === planimety.typologyCode
                )}
                appartmentName={appartmentName}
              />
            );
          })}
        />
      </div>
      <Footer></Footer>
    </Router>
  );
}

export default App;
