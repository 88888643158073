import React from 'react';
import ApartmentSizeBox from '../components/ApartmentSizeBox';

import PropTypes from 'prop-types';
import Nav from '../components/Nav';

const Typology = ({ typologies }) => {
  return (
    <>
      <Nav />
      <div className="main-container">
        <div className="apartment-size-container">
          {typologies.length > 0 &&
            typologies.map((typo, idx) => (
              <ApartmentSizeBox
                key={idx}
                sizeIcon={`home__size--0${idx + 1}.svg`}
                size={typo.name}
                link={typo.code}
                sizePriceRange={typo.price_range}
              ></ApartmentSizeBox>
            ))}
        </div>
      </div>
    </>
  );
};

Typology.propTypes = {
  typologies: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Typology;
