import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';

const PlanimeteryImage = ({ imageSrc }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setImageLoaded(false);
    const img = new Image();
    img.src = imageSrc;
    // console.log(imageSrc);
    img.onload = () => setImageLoaded(true);
  }, [imageSrc]);

  return !imageLoaded ? (
    <div
      className="planimetry-image"
      style={{
        display: 'flex',
        margin: '0',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {
        <Loader
          style={{ textAlign: 'center' }}
          type="TailSpin"
          color="#666766"
          height={100}
          width={100}
          //   timeout={3000} //3 secs
        />
      }
    </div>
  ) : (
    <div
      className="planimetry-image"
      style={{
        backgroundImage: `url(${imageSrc})`,
      }}
    ></div>
  );
};

PlanimeteryImage.propTypes = {
  imageSrc: PropTypes.string.isRequired,
};
export default PlanimeteryImage;
