import React, { useState } from 'react';
import PlanimetryBoxExtended from '../components/PlanimetryBoxExtended';
import Nav from '../components/Nav';
import FloorSelection from '../components/FloorSelection';
import PlanimeteryImage from '../components/PlainmetryImage';
import PriceBox from '../components/PriceBox';
import Pakcage from '../components/Package';
import Form from '../components/Form';
import FurnishingDescription from '../components/FurnishingDescription';
import _ from 'lodash';
import { getRate, convertToPrice, convertToCurrencyValue } from '../utils';
import PropTypes from 'prop-types';
import { getRooms } from '../utils';

const Done = ({
  typology,
  planimetry,
  customization,
  typologyCode,
  planimetryCode,
  appartmentName,
}) => {
  // all the floors

  const allAppartments = planimetry.appartments
    .map((app) => ({
      floors: app.floors,
      //name: app.name,
    }))

    .map((item) => ({
      floors: item.floors,
      //name: item.name,
      reserved: planimetry.appartments
        .filter(
          (app) => JSON.stringify(app.floors) === JSON.stringify(item.floors)
        )
        .every((app) => {
          return app.reserved === true;
        }),
    }));

  // filters the appartments by the s number indicated in the url
  const appartmentFilteredByFloorFromURL = planimetry.appartments
    .filter((item) => item.name === appartmentName)
    .map((item) => ({
      floors: item.floors,
      //name: item.name,
      reserved: planimetry.appartments
        .filter(
          (app) => JSON.stringify(app.floors) === JSON.stringify(item.floors)
        )
        .every((app) => {
          return app.reserved === true;
        }),
    }));
  // if there is a floor number and there are appartments for that floor number
  // send only that appartment's data othewise all
  const appartmenst =
    appartmentName && appartmentFilteredByFloorFromURL.length > 0
      ? appartmentFilteredByFloorFromURL
      : allAppartments;

  // save the selected floor in the state;
  const [selectedFloor, setSelectedFloor] = useState(
    appartmentFilteredByFloorFromURL.length > 0
      ? appartmentFilteredByFloorFromURL[0].floors
      : planimetry.appartments[0].floors
  );

  // save the selectedOption
  const [selectedOptions, setSelectedOptions] = useState([]);

  // get all the appartments data for the slected floor
  let appartmentsForFloor = planimetry.appartments.filter((i) =>
    i.floors.includes(parseInt(selectedFloor, 10))
  );

  if (appartmentFilteredByFloorFromURL.length > 0) {
    appartmentsForFloor = appartmentsForFloor.filter(
      (i) => i.name === appartmentName
    );
  }

  // all the available options of the appartments of the selected floor
  const options = [].concat(
    ...appartmentsForFloor
      .map((ap) => ap.options)
      .map((cur) => JSON.stringify(cur))
      .filter(function (curr, index, self) {
        return self.indexOf(curr) === index;
      })
      .map((cur) => JSON.parse(cur))
    // .map((i) => ({ id: i, name: i }))
  );

  // calcluate the minimum price from the price of the appartments
  const minPrice = _.min(appartmentsForFloor.map((itm) => itm.basePrice));
  // calclalue and format the price and rate of each configuration
  const configurations = planimetry.configurations.map((item) => ({
    ...item,
    price: convertToPrice(item.price) + convertToPrice(minPrice),
    rate: getRate(
      (convertToPrice(item.price) + convertToPrice(minPrice)).toString()
    ),
  }));

  // caluclate the final price = configuration price +  sum of slected options
  const price =
    configurations.find((itm) => itm.name === customization.furnishingName)
      .price +
    _.sum(
      options
        .filter(
          (op) => selectedOptions.filter((item) => item === op.name).length > 0
        )
        .map((item) => convertToPrice(item.price))
    );

  return (
    <>
      <Nav
        typologyCode={typologyCode}
        planimetryCode={planimetryCode}
        customization={customization}
        isAppartmentNameValid={appartmentFilteredByFloorFromURL.length > 0}
        appartmentName={appartmentName}
      />
      <div className="main-container">
        <h5 className="recap-title">
          Sie haben die Konfiguration abgeschlossen:
        </h5>

        <div className="recap-container">
          <div className="info-side">
            <PlanimetryBoxExtended
              size={typology.name}
              name={
                appartmentFilteredByFloorFromURL.length
                  ? appartmentsForFloor[0].name
                  : planimetry.name
              }
              PlanimetryMQ={planimetry.mq}
              PlanimetryImage="planimetry-sample__01.png"
              bader={planimetry.bathrooms}
              schlafzimmer={planimetry.bedrooms}
              loggia={planimetry.mq_loggia}
              loggiaType={planimetry.external_space}
            ></PlanimetryBoxExtended>

            <FurnishingDescription
              name={customization.furnishingName}
              text={customization.furnishingText}
            />
          </div>

          <div className="planimetry-side">
            <PlanimeteryImage
              imageSrc={`${process.env.PUBLIC_URL}/img/planimetry/${planimetry.name}.png`}
            />
            <PriceBox
              price={convertToCurrencyValue(price)}
              rate={convertToCurrencyValue(getRate(price.toString()))}
            />

            <a href="#form" className="primary-button">
              Download Ihrer Konfiguration
            </a>
          </div>
          <div className="choice-side">
            <FloorSelection
              selectedFloor={selectedFloor}
              setSelectedFloor={setSelectedFloor}
              setSelectedOptions={setSelectedOptions}
              appartmenst={appartmenst}
              isAppartmentNameValid={
                appartmentFilteredByFloorFromURL.length > 0
              }
            />
            <Pakcage
              allChecked
              title="Im Verkaufspreis inbegriffen"
              packageItems={[
                {
                  id: 'e1',
                  name: 'deckenbündige Einbauleuchten in Korridoren und Bädern',
                },
                {
                  id: 'e2',
                  name: 'Garderobeneinbauschränke',
                },
                {
                  id: 'e3',
                  name: 'Waschturm von V-Zug, Serie 4000',
                },
                {
                  id: 'e4',
                  name: 'Einbauküche',
                },
              ]}
            />
            <Pakcage
              title="Optional"
              packageItems={options}
              setSelectedOptions={setSelectedOptions}
              selectedOptions={selectedOptions}
            />
          </div>
        </div>

        {/* <AppointmentPicker /> */}
        <Form
          typologyCode={typologyCode}
          formImage={`${process.env.PUBLIC_URL}/img/images/form-image.jpg`}
          data={{
            tipologia: getRooms(typologyCode),
            typologyCode,
            piano: selectedFloor.map((i) => (i === 0 ? 'EG' : i)).join(','),
            nome_appartmaento: appartmentsForFloor[0].name,
            nome_planimertia: planimetry.name,
            //nome_appartmaento: appartmentsForFloor[0].name,
            mqApp: planimetry.mq,
            loggiaType: planimetry.external_space,
            numBagni: planimetry.bathrooms,
            numCamere: planimetry.bedrooms,
            mqLoggia: planimetry.mq_loggia,
            mqTot: parseFloat(planimetry.mq_loggia) + parseFloat(planimetry.mq),
            finitura: customization.furnishingName,
            finitura_bagno: customization.materialNames[1].trim(),
            finitura_parquet: customization.materialNames[0].trim(),
            upgrade:
              'Waschturm von V-Zug, Serie 4000 | Garderobeneinbauschrank | deckenbündige Einbauleuchten in Korridoren und Bädern | Einbauküche',
            optional:
              selectedOptions.length > 0 ? selectedOptions.join(' | ') : '',
            prezzo: convertToCurrencyValue(price),
            rata: convertToCurrencyValue(getRate(price.toString())),
          }}
        />
      </div>
    </>
  );
};

Done.defaultProps = {
  appartmentName: '',
};

Done.propTypes = {
  typology: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
  planimetry: PropTypes.shape({
    name: PropTypes.string.isRequired,
    mq: PropTypes.string.isRequired,
    bathrooms: PropTypes.string.isRequired,
    bedrooms: PropTypes.string.isRequired,
  }).isRequired,
  appartmentName: PropTypes.string,
  // customization,
  // typologyCode,
  // planimetryCode,
};

export default Done;
