import actionTypes from './actionTypes';
const initialState = {};

export const config = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SELECT_TYPOLOGY: {
      return {
        typologyCode: payload,
      };
    }
    case actionTypes.SELECT_PLANIMETRY: {
      const { typologyCode, planimetry } = payload;
      return {
        typologyCode,
        planimetry,
      };
    }
    case actionTypes.SET_CUSTOMIZATION: {
      const { typologyCode, planimetry, customization } = payload;
      return {
        typologyCode,
        planimetry,
        customization,
      };
    }
    case actionTypes.RESET_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};
