import React from 'react';
import PropTypes from 'prop-types';

const PriceBox = ({ price, rate }) => {
  return (
    <div className="price-box">
      <h5>Der Preis für Ihre Auswahl</h5>
      <br />
      <h2>CHF {price}</h2>
      <hr />
      <h5 className="rate">MONATL. Rate* CHF {rate} </h5>
    </div>
  );
};

PriceBox.propTypes = {
  price: PropTypes.string.isRequired,
  rate: PropTypes.string.isRequired,
};
export default PriceBox;
