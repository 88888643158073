/**
 * a number formatter for € currency
 */
export const currencyFormatter = new Intl.NumberFormat('ch-CH', {
  style: 'currency',
  currency: 'CHF',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});
/**
 *
 * @param {*} price
 */
export const getRate = (price) => {
  return Math.round((parseFloat(price) * 0.8 * 0.027) / 12);
};

export const convertToPrice = (string) => {
  return parseFloat(string.split('.').join(''));
};

export const convertToCurrencyValue = (price) => {
  return new Intl.NumberFormat('de-CH', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })
    .format(price)
    .replace(',', "'");
};

export const getNext = (planimetries, plan) => {
  const index = planimetries.findIndex((item) => item.name === plan.name);
  if (index >= 0 && index < planimetries.length - 1)
    return planimetries[index + 1];

  return null;
};
export const getPrevious = (planimetries, plan) => {
  const index = planimetries.findIndex((item) => item.name === plan.name);
  if (index > 0 && index < planimetries.length) return planimetries[index - 1];
  return null;
};
export const getFloors = (plan) => {
  const floors = [].concat(
    ...plan.appartments.map((app) => app.floors.join(','))
  );
  return floors
    .filter((item, index) => floors.indexOf(item) === index)
    .map((i) => (i === '0,1' ? 'EG,1' : i))
    .join(' | ');
};

export const getRooms = (typologyCode) => {
  switch (typologyCode) {
    case 'DUPLEX':
      return 1;
    case 'Z2.5':
      return 2;
    case 'Z3.5':
      return 3;
    case 'Z4.5':
      return 4;

    default:
      break;
  }
};

export async function getPDFfilename(PDF_URL, data) {
  try {
    const urlData = new URLSearchParams(data);

    const result = await fetch(PDF_URL + 'form_configurator_preventivo.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: urlData,
    })
      .then((resp) =>
        resp
          .json()
          .then((data) => Promise.resolve({ json: data, response: resp }))
      )

      .catch(function (e) {
        console.log('another error happened:' + e.message);
      });

    return result;
  } catch (ex) {
    return null;
  }
}

export function padDigits(number, digits) {
  return (
    Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number
  );
}

export function getQueryStrings(item) {
  return decodeURI(item)
    .replace('?', '')
    .split('&')
    .map((param) => param.split('='))
    .reduce((values, [key, value]) => {
      values[key] = value;
      return values;
    }, {});
}
