import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { getRooms } from '../utils';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import Dialogue from './AppointmentPicker/Dialogue';

import { API_URL, PDF_URL } from '../config';
import { getPDFfilename } from '../utils';
//const ApiURI = '/api/';

export async function submitData(input) {
  try {
    const urlData = new URLSearchParams({
      calendar_require: 0,
      API_POST: 1,
      pdf: input.pdf,
      calendar_anrede: input.calendar_anrede,
      'calendar_rooms[]': input.calendar_rooms,
      calendar_vorname: input.calendar_vorname,
      calendar_nachname: input.calendar_nachname,
      calendar_strasse: input.calendar_strasse,
      calendar_plz: input.calendar_plz,
      calendar_ort: input.calendar_ort,
      calendar_email: input.calendar_email,
      calendar_telefon: input.calendar_telefon,
      calendar_bemerkungen: input.calendar_bemerkungen,
      calendar_visitors: input.calendar_visitors,

      id: 1099,
    });

    const result = await fetch(API_URL + 'setAppointment/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: urlData,
    })
      .then((resp) => {
        console.log(resp);
        return resp.text();
      })

      .catch(function (e) {
        console.log('another error happened:' + e.message);
      });

    return result;
  } catch (ex) {
    return null;
  }
}
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 350px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FormModal = ({
  modalIsOpen,
  closeModal,
  formData,
  afterDone,
  typologyCode,
  pdffile,
  pdfData,
}) => {
  const [confirm, setConfim] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSent, setIsSent] = useState(false);
  const [pdf, setPDF] = useState(null);

  useEffect(() => {
    if (modalIsOpen && pdf === null) {
      getPDFfilename(PDF_URL, pdfData).then((l) => {
        setPDF('php/files/' + l.json.filename);
      });
    }
    const timer = setTimeout(() => {
      if (modalIsOpen && !isSent && pdf !== null)
        submitData(submittedData).then((e) => {
          setLoading(false);
          setIsSent(true);
          // console.log('response is', e);

          if (e === 'OK') {
            setConfim(true);
          } else {
            setShowError(true);
          }
        });
    }, 500);
    return () => clearTimeout(timer);
  }, [isSent, modalIsOpen, pdf, pdfData]);

  const submittedData = {
    calendar_rooms: getRooms(typologyCode),
    calendar_anrede: formData.sex,
    calendar_vorname: formData.firstName,
    calendar_nachname: formData.lastName,
    calendar_strasse: formData.address,
    calendar_plz: formData.postcode,
    calendar_ort: formData.location,
    calendar_email: formData.email,
    calendar_telefon: formData.phone,
    calendar_bemerkungen: formData.note,
    calendar_visitors: formData.visitors,
    pdf: 'https://tender-homeconfigurator.lokstadt.ch/' + pdf,
  };
  // console.log(submittedData);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 12,
        },
        content: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          border: '1px solid #000',
          background: '#fff',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '0px',
          outline: 'none',
          padding: '7px',
        },
      }}
    >
      <div className="PlanimetryBox">
        <Wrapper>
          {loading === true ? (
            <Loader type="TailSpin" color="#d1d1d1" height={100} width={100} />
          ) : confirm ? (
            <>
              <Dialogue
                open={confirm}
                title="
            "
                logoClass="question_logo"
                subtitle=" Sehr geehrte Damen und Herren 
                ,Es freut uns, dass Sie sich für das urbane Wohnen im Tender in der Lokstadt interessieren.
                Wir werden Ihnen in Kürze Informationen im Tender zukommen lassen.
                Freundliche Grüsse, Ihr Implenia Verkaufsteam
                (tender@lokstadt.ch)"
                dialogueButtons={[
                  {
                    text: 'OK',
                    type: 'yes',
                    handleClick: () => {
                      setConfim(false);
                      closeModal();
                      setLoading(true);
                      afterDone();
                      // done();
                    },
                  },
                ]}
              />
              <div className="confirm"></div>
            </>
          ) : showError ? (
            <Dialogue
              open={showError}
              title="Fehler"
              logoClass="question_logo"
              subtitle="Es ist ein Fehler aufgetreten. Versuchen Sie es erneut."
              dialogueButtons={[
                {
                  text: 'OK',
                  type: 'no',
                  handleClick: () => {
                    setShowError(false);
                    setLoading(true);
                    closeModal();
                  },
                },
              ]}
            />
          ) : null}
        </Wrapper>
      </div>
      <span className="modal-close" onClick={() => closeModal()}></span>
    </Modal>
  );
};
export default FormModal;
