import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';


const Button = styled.div`
  display: flex;
  width: 100px;
  height: 40px;
  margin: 7px;
  justify-content: center;
  align-items: center;
  font-family: Montserrat, sans-serif;
  color: #fff;
  cursor: pointer;
  ${(props) =>
    props.type === 'yes'
      ? `background-color: #3bad1f;`
      : props.type === 'no'
      ? `background-color: #aa2016;`
      : ''}
`;


const Dialogue = (props) => {
  const {
    open,
    title,
    subtitle,
    subtitle2,
    logoClass,
    date,
    dialogueButtons,
  } = props;

  return (
    open && (
      <div className="modal">
              <div className="modal_wrapper">
          <div>
            <div className={logoClass} />
            <div className="modal_text-container">
            <div className="modal_title">{title}</div>
            <div className="modal_subtitle">
                {subtitle}
                <br />
                {subtitle2}
                <br />
                {date && <b>{date}</b>}
              </div>
            </div>

            {dialogueButtons && (
                   <div className="modal_choice-button">
                {dialogueButtons.map((item, index) => (
                  <Button
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    type={item.type}
                    onClick={item.handleClick}
                    onKeyPress={item.handleClick}
                    tabIndex="0"
                  >
                    {item.text}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

Dialogue.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  subtitle2: PropTypes.string,
  logoClass: PropTypes.string,
  date: PropTypes.string,
  dialogueButtons: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      handleClick: PropTypes.func.isRequired,
    }).isRequired
  ).isRequired,
};

Dialogue.defaultProps = {
  subtitle: '',
  subtitle2: '',
  logoClass: '',
  date: '',
};

export default Dialogue;
