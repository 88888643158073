import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const ApartmentSizeBox = ({ sizeIcon, size, sizePriceRange, link }) => {
  return (
    <div className="SizeBox">
      <div
        className="size-icon"
        style={{
          backgroundImage: `url(${require(`../img/icons/${sizeIcon}`)})`,
        }}
      ></div>
      <h2>{size}</h2>
      <h5 style={{ textTransform: 'none' }}>{sizePriceRange}</h5>
      <hr></hr>
      <Link className="primary-button" to={'./' + link}>
        AUSWÄHLEN
      </Link>
    </div>
  );
};

ApartmentSizeBox.propTypes = {
  sizeIcon: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  sizePriceRange: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};
export default ApartmentSizeBox;
