import React, { useState } from 'react';
import PropTypes from 'prop-types';

const FloorSelection = ({
  appartmenst,
  selectedFloor,
  setSelectedFloor,
  setSelectedOptions,
  isAppartmentNameValid,
}) => {
  const [isChecked, setIsChecked] = useState(0);
  return (
    <div className="choice-plan">
      <h5>
        {isAppartmentNameValid
          ? 'AUSGEWÄHLTE ETAGE'
          : 'Wählen Sie zwischen den verfügbaren Etagen aus'}
        :{' '}
      </h5>
      {appartmenst.map((item, idx) => (
        <React.Fragment key={idx}>
          <input
            className="custom-radio"
            name2={item.floors}
            type="radio"
            id={`radio_${item.floors}`}
            checked={
              selectedFloor
                ? item.floors.includes(selectedFloor[0])
                : isChecked === idx
            }
            name="choice-plan"
            onChange={() => {
              // console.log(idx);
              setIsChecked(idx);
              setSelectedFloor(item.floors);
              setSelectedOptions([]);
            }}
          />
          <label htmlFor={`radio_${item.floors}`}>
            <span></span> Etage{' '}
            {item.floors.map((i) => (i === 0 ? 'EG' : i)).join(',')}
            {item.reserved && <b className="reserved">reserviert</b>}
          </label>
        </React.Fragment>
      ))}
    </div>
  );
};

FloorSelection.defaultProps = {
  selectedFloor: [],
};
FloorSelection.propTypes = {
  appartmenst: PropTypes.arrayOf(
    PropTypes.shape({
      floors: PropTypes.arrayOf(PropTypes.number.isRequired),
      reserved: PropTypes.bool.isRequired,
    })
  ).isRequired,
  setSelectedFloor: PropTypes.func.isRequired,
  setSelectedOptions: PropTypes.func.isRequired,
  selectedFloor: PropTypes.arrayOf(PropTypes.number),
  isAppartmentNameValid: PropTypes.bool.isRequired,
};

export default FloorSelection;
