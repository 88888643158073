import React from 'react';

import PlanimeteryImage from './PlainmetryImage';

const PlanimetryBox = (props) => {
  return (
    <div className="PlanimetryBox">
      <div className="planimetry-info">
        <h5 className="size">{props.size}</h5>
        <h2>{props.name}</h2>
        <hr></hr>
        <h5 className="mq">
          {props.PlanimetryMQ} m<sup>2</sup>
        </h5>
        <h5 className="price">AB CHF {props.PlanimetryPrice}</h5>
        <hr className="variant-2"></hr>
        <h5 className="rate">monatl. Rate* AB CHF {props.PlanimetryRate}</h5>
        <div
          className="primary-button"
          onClick={() => props.setModalIsOpen(true)}
        >
          AUSWÄHLEN
        </div>
      </div>

      <PlanimeteryImage
        imageSrc={`${process.env.PUBLIC_URL}/img/planimetry/${props.name}.png`}
      />
    </div>
  );
};

export default PlanimetryBox;
