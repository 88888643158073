import React from 'react';

const Disclaimer = () => {
  return (
    <div className="main-container info-container">
      <h2>Disclaimer</h2>
      <p>Alle Angaben in dieser Dokumentation sind unverbindlich. Änderungen bleiben vorbehalten bis Bauvollendung.
        Die dargestellten Bilder und Pläne dienen der allgemeinen Information.  <br />
        Sie bilden keinen integrierenden Bestandteil einer vertraglichen Vereinbarung, und es können keinerlei
        Ansprüche daraus abgeleitet werden. Stand Mai.</p>

      <p>Bei der monatlichen Hypothekarrate handelt es sich um ein Beispiel, das auf Basis folgender Berechnungsgrundlagen
        ermittelt wurde: 20% eigene Mittel, 80% Belehnung, 1.0% Hypothekarzins, 1.0% Amortisation, 0.7% Unterhalts- und Nebenkosten.  <br />
Die hier ermittelten und abgebildeten Berechnungsergebnisse dienen einzig zu Informationszwecken. Sie stellen weder eine
Beratung noch eine Empfehlung oder ein Angebot zum Erwerb oder Verkauf von Produkten oder zur Tätigung sonstiger
Transaktionen oder zum Abschluss irgendeines Rechtsgeschäfts mit der Implenia, einer ihrer Gruppengesellschaften oder Dritten dar.
Weder die Implenia AG noch eine ihrer Gruppengesellschaften übernimmt die Verantwortung für die Richtigkeit und Vollständigkeit dieser Angaben.
Jede Haftung für Schäden bzw. Verluste, die sich aus Dispositionen gestützt auf diese Berechnungsergebnisse ergeben, wird abgelehnt.</p>
    </div>
  );
};



export default Disclaimer;
