import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>
      <NavLink to="/disclaimer">*Disclaimer</NavLink>
      <a
        href="https://www.lokstadt.ch/de-ch/rechtliches.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy
      </a>
      <a
        href="https://www.tecmasolutions.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Credits
      </a>
    </footer>
  );
};

export default Footer;
