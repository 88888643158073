import React from 'react';
import PropTypes from 'prop-types';

const Package = ({
  title,
  packageItems,
  allChecked,
  setSelectedOptions,
  selectedOptions,
}) => {
  return (
    <div>
      <h5>{title}</h5>
      {packageItems.map((item) => {
        // console.log(item);

        return (
          <React.Fragment key={item.id}>
            <input
              className="custom-checkbox"
              type="checkbox"
              id={item.id}
              checked={allChecked ? true : selectedOptions.includes(item.name)}
              onChange={() => {
                if (!allChecked) {
                  if (!selectedOptions.includes(item.name))
                    setSelectedOptions([...selectedOptions, item.name]);
                  else
                    setSelectedOptions(
                      selectedOptions.filter((option) => option !== item.name)
                    );
                }
              }}
            />
            <label htmlFor={item.id}>
              {item.name}
              {item.price && (
                <>
                  {' '}
                  <br />
                  <span>{`( + CHF ${item.price.replace('.', "'")}.- )`}</span>
                </>
              )}
            </label>
          </React.Fragment>
        );
      })}
    </div>
  );
};

Package.defaultProps = {
  allChecked: false,
  setSelectedOptions: () => false,
  selectedOptions: [],
};
Package.propTypes = {
  title: PropTypes.string.isRequired,
  // packageItems: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     id: PropTypes.string.isRequired,
  //     name: PropTypes.string.isRequired,
  //   })
  // ).isRequired,
  packageItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  allChecked: PropTypes.bool,
  setSelectedOption: PropTypes.func,
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
};

export default Package;
